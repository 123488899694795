<template>
  <CSidebar
    aside
    :show="$store.state.asideShow"
    @update:show="(val) => $store.commit('set', ['asideShow', val])"
    colorScheme="light"
    overlaid
    size="lg"
    style="max-height: 100vh; overflow-y: auto;"
  >
    <CSidebarClose @click.native="$store.commit('toggle', 'asideShow')"/>
    <CTabs tabs class="nav-underline nav-underline-info">
      <CTab active>
        <template slot="title">
          <CIcon name="cil-bell"/>
        </template>
        <CListGroup class="list-group-accent">
          <CListGroupItem
            class="list-group-item-accent-secondary bg-light text-center
            font-weight-bold text-muted text-uppercase small"
          >
            <BInputGroup size="sm" prepend="경보">
              <BFormSelect size="sm"
                           class="mr-2"
                           v-model="fromDays"
                           style="background-color:#2f303a"
                           @input="selectDayOpts"
                           :options="dayFromOpts"
              />
              <BInputGroupAppend>
                <BButton size="sm" variant="danger" style="min-width:80px" pill>
                  <strong>{{comma($store.state.alarm.count.total)}}</strong>
                </BButton>
              </BInputGroupAppend>
            </BInputGroup>
          </CListGroupItem>

          <CListGroupItem v-for="(evtName,key) in eventCodeMap" :key="key"
                          :href="`/#/monitor/events?code=${key}&from=${fromDays}`"
                          :class="`list-group-item-accent-${eventColor[key]}`">
            <div class="float-left">
              <BButton size="sm" :variant="eventColor[key]" @click="getEventTankCount(key)">
                <strong>{{ evtName }}</strong>
              </BButton>
            </div>
            <BSpinner variant="danger" class="ml-1 mt-2" small v-show="evtReceived[key]" type="grow" label="Spinning"/>
            <div class="float-right">
              <BButton size="sm"
                       class="ml-1"
                       :variant="`outline-${eventColor[key]}`"
                       style="min-width:90px" pill>
                <strong>{{comma($store.state.alarm.count[key])}}</strong>
              </BButton>
            </div>
          </CListGroupItem>

          <CCard v-show="isShowEventTank" class="mt-3">
            <CCardBody>
              <BRow>
                <BCol class="text-center">
                  <BButtonGroup size="sm" class="mb-2">
                    <BButton :variant="eventColor[evtCode]" pill>
                      <BIconBellSlashFill class="mr-1"/> {{ eventCodeMap[evtCode] }} | <b>알람 확인/지연 설정</b>
                    </BButton>
                  </BButtonGroup>
                </BCol>
              </BRow>

              <BRow v-for="(e,i) in eventTanks" :key="i">
                <BCol>
                  <BInputGroup size="sm" class="small mb-1">
                    <BButtonGroup size="sm" class="mr-1">
                      <BButton variant="outline-info" class="font-sm small" style="min-width:70px">{{tankMap[e.tid].name}}</BButton>
                    </BButtonGroup>
                    <BFormSelect size="sm"
                                 style="background-color:#2f303a"
                                 v-model="suppress.min"
                                 :options="suspendOpts"/>
                    <BInputGroupAppend>
                      <BButton size="sm" variant="warning" @click="setSuppressAlarm(e.tid)">
                        알람지연
                      </BButton>
                    </BInputGroupAppend>
                  </BInputGroup>
                </BCol>
              </BRow>

              <BRow>
                <BCol>
                  <BInputGroup size="sm">
                    <BFormSelect size="sm"
                                 v-model="suppress.tid"
                                 style="background-color:#2f303a"
                                 :options="tankOpts"/>
                    <BFormSelect size="sm"
                                 v-model="suppress.min"
                                 style="background-color:#2f303a"
                                 :options="suspendOpts"/>
                    <BInputGroupAppend size="sm">
                      <BButton size="sm" variant="warning" @click="setSuppressAlarm(suppress.tid)">알람지연</BButton>
                    </BInputGroupAppend>
                  </BInputGroup>
                </BCol>
              </BRow>
              <BTableSimple class="small mt-1" small bordered sticky-header>
                <BTr class="text-center bg-gradient-dark">
                  <BTd width="80">경보</BTd><BTd>알람 지연</BTd>
                </BTr>
                <BTr v-for="(obj,k) in suppressAlarms" :key="k">
                  <BTd class="text-center">
                    <BBadge :variant="eventColor[k]">
                    {{ eventCodeMap[k].replace('경보','') }}
                    </BBadge>
                  </BTd>
                  <BTd>
                    <BRow v-for="(dt,tid) in obj" :key="tid">
                      <BCol cols="3"><BBadge>{{ tankMap[tid].name }}</BBadge></BCol>
                      <BCol>
                        <BBadge variant="dark">{{`~ ${toShortTime(dt)}`}}</BBadge>
                      </BCol>
                    </BRow>
                  </BTd>
                </BTr>
              </BTableSimple>
            </CCardBody>
          </CCard>
        </CListGroup>
      </CTab>
    </CTabs>
  </CSidebar>
</template>

<script>
import moment from "moment";
import {
  apiCall, beepSound, speech, comma
} from '../common/utils';
import qs from 'querystring';

export default {
  name: 'TheAside',
  data () {
    return {
      comma,
      suppress:{
        tid: '',
        evtCode: '',
        min: 10,
      },
      suppressAlarms: null,
      evtReceived: {},

      fromDays: 3,
      fromDts: null,
      toDts: null,
      dayFromOpts: [
        {value:3, text:'최근 3일'},
        {value:7, text:'최근 7일'},
        {value:14, text:'최근 2주'},
        {value:28, text:'최근 4주'},
        {value:90, text:'최근 3개월'},
      ],
      eventCodeMap: this.$store.state.codeMaps['EVENT'],
      tankMap: this.$store.state.tanks['map'],
      tankOpts: this.$store.state.tanks['opts'],
      eventColor: {
        '1':'warning', // 넘침
        '2':'primary', // 부족
        '3':'danger',  // 화재
        '4':'info',    // 수분
        '5':'success',  // 누유
        '6':'danger',  // 넘침2
        '7':'dark',    // 부족2
        '8':'light',   // 점검?
        '9':'secondary'  // 센서점검
      },
      alarmCfg: this.$store.state.alarm,
      isShowEventTank: false,
      evtCode: '1',
      eventTanks: [],
      suspendOpts: [
        {value:2, text:'2 분'},
        {value:5, text:'5 분'},
        {value:10, text:'10 분'},
        {value:30, text:'30 분'},
        {value:60, text:'1 시간'},
        {value:180, text:'3 시간'},
        {value:360, text:'6 시간'},
        {value:12*60, text:'12 시간'},
        {value:24*60, text:'24 시간'},
      ],
      suppressInterval: null,

    }
  },
  computed: {},
  created() {
    this.fromDays = this.$store.state.alarm.count.fromDays? this.$store.state.alarm.count.fromDays:3;

    setTimeout( async ()=>{
      console.warn("[MONITOR] ############ register atgDataHandler #########");
      this.socket = this.$store.state.socket;
      if(this.socket) {
        this.socket.removeListener('event', this.eventAlarmHandler)
        this.socket.on('event', this.eventAlarmHandler); // { tid, tnm, cmd, dt, payload }
      }
      console.warn( "[MONITOR] ############ socket object ------> is connecting? ", this.socket.connected );
    }, 3000);

  },
  async mounted() {
    await this.getSuppressAlarms();

    this.suppressInterval = setInterval( async ()=>{ await this.getSuppressAlarms() }, 1000 * 60);
    this.fromDts =  moment().subtract(this.fromDays, 'days').toISOString();
    this.toDts = moment().toISOString();

    await this.getCount();
    if(!this.$store.state.soundOn){
      if( await this.confirmModal('브라우저 사운드 설정이 Off 입니다. 사운드 설정을 On 합니다.') ){
        this.$store.commit('toggle', 'soundOn');
      }
    }

  },
  methods: {
    eventAlarmHandler(data){
      // console.log('eventAlarmHandler--- data --->', data)
      // data = { tid, tnm=tankName, cmd, dt, payload }
      const {payload} = data;
      if(payload.length) {
        // speech(`경보 알람 수신`);

        payload.map(async e => {
          // 다음 알람 시간정보 구성
          console.debug( 'eventAlarmHandler --- payload', e );
          this.$store.state.alarm.count[e.code]++;
          this.$store.state.alarm.count['total']++;

          this.evtReceived[e.code] = true;
          setTimeout(()=>{ this.evtReceived[e.code]=false}, 1500);

          // console.log( this.$refs[`btn_count_${e.code}`] );

          if( !this.isAlarmSuppressed(e.code, e.tid) ) {
            await this.consoleAlarm(e);
          }

        })
      }
    },


    async selectDayOpts(){
      this.toDts = moment().format('YYYY-MM-DD');
      this.fromDts = moment().subtract(this.fromDays,'days').format('YYYY-MM-DD');
      await this.getCount();
    },

    async getCount() {
      let paramMap = {};
      try{
        this.$store.state.alarm.count.fromDays = this.fromDays;
        paramMap['fromDts'] = moment().subtract(this.fromDays, 'days').toISOString();
        if(this.toDts) paramMap['toDts'] = this.toDts;
        let query = qs.stringify( paramMap )
        const {result} = await apiCall('get', `/api/event/count?${query}`);
        // console.log(result);
        let totalCount = 0;
        let alarmCount = this.$store.state.alarm.count;

        Object.keys(alarmCount).map(k=>{ alarmCount[k] = 0;});

        result.map(r=>{
        alarmCount[r.code] = r.count;
          totalCount += r.count;
        })
        alarmCount.total = totalCount;

        await speech(`최근 ${this.fromDays}일 동안, ${totalCount} 건의 경보 발생.`);

      }catch(err){
        console.log(err);
      }
    },


    async getEventTankCount(code){
      let paramMap = {};
      try{

        this.evtCode = code;
        paramMap['code'] = code;
        if(this.fromDts) paramMap['fromDts'] = this.fromDts;
        if(this.toDts) paramMap['toDts'] = this.toDts;

        let query = qs.stringify( paramMap )
        this.eventTanks = [];
        const {result} = await apiCall('get', `/api/event/tank-count?${query}`);
        if(result) {
          this.eventTanks = result;
        }
        console.log('showEventTank result --->', result);

      }catch(err){
        console.log(err);
      }finally{
        this.isShowEventTank = true;
      }
    },
    /** 알람 확인/지연 설정 */
    async setSuppressAlarm(tid){
      try{

        console.log( 'setSuppressAlarm --- tid --->', tid)
        this.suppress.tid = tid;

        if(!this.suppress.tid) {
          this.toastWarn('탱크를 선택 하세요');
          return;
        }

        this.suppress.evtCode = this.evtCode;
        const r = await apiCall('put', `/api/alarm/suppress`, this.suppress);
        if(r.code===200) await this.getSuppressAlarms();
        await this.toastResult(r);
      }catch(err){
        console.log(err);
      }
    },

    async getSuppressAlarms(){
      try{
        const r = await apiCall('get', `/api/alarm/suppress`, this.suppress);
        this.suppressAlarms = r.result;
      }catch(err){
        console.log(err);
      }
    },

    async consoleAlarm(e){
      let cfg = this.alarmCfg.config;
      let tankCfg = this.alarmCfg.tank;

      try{
        if(!cfg.enabled) {
          // console.log('alarm disabled')
          return;
        }

        let tankAlarm = tankCfg[e.tid];

        if(!tankAlarm.enabled) {
          // console.log('tankAlarm disabled')
          return;
        }

        let isAlarm = cfg.events.find(evtCfg=>{
          return (evtCfg.code===e.code)? evtCfg.enabled:false
        });

        let isTankAlarm = tankAlarm.events.find(te=>{
          return (te.code===e.code)? te.enabled:false;
        });

        if(isAlarm){
          // TTS 출력
          // console.log('consoleAlarm e --->', e);

          if( isTankAlarm && cfg.console.tts && tankAlarm.console.tts) {
            speech(`${e.name}!`);
          }

          // 토스트 알림
          if(isTankAlarm && cfg.console.icon && tankAlarm.console.icon) {
            this.toastAlarm(`[${e.name}] ${e.tid}`, e.text, this.eventColor[e.code]);
          }

          // 비프 사운드
          if(isTankAlarm && cfg.console.sound && tankAlarm.console.sound ) {
            if(['3', '6', '7'].includes(e.code) )
              await beepSound('danger');
            else
              await beepSound('warning');
          }
        }
      }catch(err){
        console.log(err);
      }
    },

    /**
     *
     * @param evtCode
     * @param tid
     * @returns {boolean}
     */
    isAlarmSuppressed( evtCode, tid ){
      let suppressedEvt = this.suppressAlarms[evtCode];
      let stopTo = suppressedEvt[tid];
      // console.log( 'evtCode, stopTo  --->', evtCode, tid, stopTo );
      if( stopTo ){
        if( moment(stopTo).isAfter() ) {
          // console.debug('[isAlarmSuppressed] checkSuspend alarm suppressed --->', evtCode, tid, this.toLocalTime(stopTo));
          return true;
        } else {
          delete this.suppressAlarms[evtCode][tid];
          return false;
        }
      }else{
        return false;
      }
    },

  },
  beforeDestroy(){
    // using "removeListener" here, but this should be whatever $socket provides
    // for removing listeners
    if(this.socket) this.socket.removeAllListeners('event-alarm', this.eventAlarmHandler );
    if(this.suppressInterval ) clearInterval( this.suppressInterval );
  },
}
</script>
<style>
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #2f303a;
}

::-webkit-scrollbar-thumb {
  background-color: #4a4c59;
  border-radius: 10px;
  border: 3px solid #2f303a;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
</style>
